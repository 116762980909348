/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ReactNotifications from "react-notifications-component";
import * as Sentry from "@sentry/react";

import { useQuery, useMutation } from "@apollo/client";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import {
  TEAM_QUERY,
  HISTORY_QUERY,
  STORIES_QUERY,
  WEEKDAY_INTERACTIONS_QUERY,
  GET_BURNDOWN_QUERY,
  GET_VELOCITY_TREND,
  MEMBER_LOAD_QUERY
} from "../../graphql/queries";
import { POST_RUNWEEK_UPDATES } from "../../graphql/mutations";
import Loading, { Error } from "../../components/Loading";
import InfoCard from "./components/InfoCard";
import DailyHistory from "./components/DailyHistory";
import ProgressCard from "./components/ProgressCard";
import CommunicationsCard from "./components/CommunicationsCard";
import Notification from "../../components/Notification";
import { useAsyncState } from "../../utilities/utils";
import { ProjectContext } from "../../GlobalState";
import ChatMessageNotification from "../../components/Chat/ChatMessageNotification";

const StyledCardBody = styled(CardBody)`
  align-items: center;
`;

const RunSprintContainer = props => {
  const [state, dispatch] = useContext(ProjectContext);
  const history = useHistory();
  const [infoOpen, toggleInfoOpen] = useAsyncState(false);
  const [runWeekStatusObj, setRunWeekStatusObj] = useAsyncState({
    conditionResponse: 0,
    returnStatus: "OFF",
    returnMessage: "",
    conditionResponseList: [],
    playScriptList: [],
    currentWeekDay: "MONDAY",
    teamId: 0,
    condition: {
      id: 0,
      conditionId: 0,
      activeEntity: "",
      condAnnouncement: "",
      condMessage: "",
      condTitle: "",
      conditionEnabled: true,
      dayFired: 0,
      hasFired: false,
      responsePrompt1: "",
      responsePrompt2: "",
      responsePrompt3: "",
      silent: false
    },
    interimHistory: []
  });
  const [conditionResponse, setConditionResponse] = useAsyncState(0);

  const { data: teamData, loading: teamLoading, error: teamError } = useQuery(
    TEAM_QUERY,
    {
      variables: { teamId: state.currentTeamId },
      skip: !state || state.currentTeamId === 0,
      onCompleted: async data => {
        if (data && data.team) {
          const payload = {
            currentTeamStatus: data.team.currentStatus,
            currentSprintId: data.team.currentSprintId,
            currentSprintNumber: data.team.currentSprintNumber,
            currentWeekNumber: data.team.currentWeekNumber,
            currentWeekDay: data.team.currentWeekDay,
            currentRunDay: data.team.currentRunDay
          };
          await dispatch({
            type: "RUN_WEEK_UPDATE",
            payload
          });
        }
      }
    }
  );
  const [
    postRunWeekUpdates,
    { loading: pLoading, error: pError }
  ] = useMutation(POST_RUNWEEK_UPDATES, {
    variables: {
      id: state.currentTeamId,
      status: "OFF"
    },
    errorPolicy: "all",
    awaitRefetchQueries: false,
    refetchQueries: [
      {
        query: TEAM_QUERY,
        variables: { teamId: state.currentTeamId }
      },
      {
        query: HISTORY_QUERY,
        variables: { teamId: state.currentTeamId }
      },
      {
        query: STORIES_QUERY,
        variables: { teamId: state.currentTeamId }
      },
      {
        query: WEEKDAY_INTERACTIONS_QUERY,
        variables: {
          teamId: state.currentTeamId,
          sprintId: state.currentSprintId,
          weekNumber: state.currentWeekNumber
        }
      },
      {
        query: GET_VELOCITY_TREND,
        variables: { teamId: state.currentTeamId }
      },
      {
        query: GET_BURNDOWN_QUERY,
        variables: {
          teamId: state.currentTeamId,
          sprintId: state.currentSprintId
        }
      },
      {
        query: MEMBER_LOAD_QUERY,
        variables: {
          teamId: state.currentTeamId,
          sprintId: state.currentSprintId
        }
      }
    ],
    onCompleted: async () => {
      const payload = {
        currentTeamStatus: teamData.team.currentStatus,
        currentSprintId: teamData.team.currentSprintId,
        currentSprintNumber: teamData.team.currentSprintNumber,
        currentWeekNumber: teamData.team.currentWeekNumber,
        currentWeekDay: teamData.team.currentWeekDay,
        currentRunDay: teamData.team.currentRunDay
      };

      await dispatch({
        type: "RUN_WEEK_UPDATE",
        payload
      });
      history.push("/reports/history");
    }
  });
  if (teamError) return <Error error={teamError} />;

  const unReadMessages = () => {
    // if (teamData && teamData.team.messages) {
    //   return teamData.team.messages.filter(
    //     e =>
    //       e.isRead === false &&
    //       e.triggerWeek === teamData.team.currentWeekNumber
    //   );
    // }
    return [];
  };
  const toggleInfoCard = () => {
    toggleInfoOpen(!infoOpen);
  };

  if (!teamData || teamLoading) {
    return <Loading />;
  }

  return (
    <>
      <ChatMessageNotification />
      <ReactNotifications />
      <Row>
        <Col md="12">
          <InfoCard cardInfoOpen={infoOpen} toggleInfoCard={toggleInfoCard} />
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <ProgressCard
            currentWeekDay={teamData.team.currentWeekDay}
            runWeekStatus={runWeekStatusObj}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <b>Interactions</b>
            </CardHeader>
            <StyledCardBody>
              <CommunicationsCard
                team={teamData.team}
                loading={teamLoading}
                projectContext={state}
                classroomStatus={props.currentUser.currentClassroom.status}
                runWeekStatusObj={runWeekStatusObj}
                conditionResponse={conditionResponse}
                updateResponse={setConditionResponse}
                updateRunWeekStatus={setRunWeekStatusObj}
                postRunWeekUpdates={postRunWeekUpdates}
              />
            </StyledCardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <Col lg="2">
                {isAdmin && email !== "samirpenkar@simulationpl.com" && (
                  <Card>
                    <CardHeader>Auto Run</CardHeader>
                    <StyledCardBody>
                      <AutoRun />
                    </StyledCardBody>
                  </Card>
                )}
              </Col> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <DailyHistory
            parentProcess="RUNWEEK"
            weekDay={teamData.team.currentWeekDay}
            weekNumber={teamData.team.currentWeekNumber}
            hideData={teamData.team.weekIsComplete}
            teamId={state.currentTeamId}
            history={
              runWeekStatusObj && runWeekStatusObj.interimHistory
                ? runWeekStatusObj.interimHistory
                : []
            }
          />
        </Col>
      </Row>
      {unReadMessages().length > 0 ? (
        <Notification unReadMessages={unReadMessages()} />
      ) : (
        ""
      )}
    </>
  );
};
export default Sentry.withProfiler(RunSprintContainer);
