/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Alert } from "reactstrap";
import { useMutation } from "@apollo/client";
import {
  RUN_WEEK_MUTATION,
  PROCESS_CONDITION_RESPONSE
} from "graphql/mutations";
import {
  TEAM_QUERY,
  HISTORY_QUERY,
  STORIES_QUERY,
  WEEKDAY_INTERACTIONS_QUERY,
  GET_BURNDOWN_QUERY,
  GET_VELOCITY_TREND,
  MEMBER_LOAD_QUERY
} from "graphql/queries";
import ConditionPanel from "./ConditionPanel";
import AssistantPanel from "./AssistantPanel";
import RunButton from "./RunButton";
import { ProjectContext } from "../../../GlobalState";

const CommunicationsCard = props => {
  const [state, dispatch] = useContext(ProjectContext);
  console.log(state);
  const [runWeek, { loading: rwLoading, error: rwError }] = useMutation(
    RUN_WEEK_MUTATION,
    {
      refetchQueries: [
        {
          query: TEAM_QUERY,
          variables: { teamId: state.currentTeamId }
        },
        {
          query: HISTORY_QUERY,
          variables: { teamId: state.currentTeamId }
        },
        {
          query: STORIES_QUERY,
          variables: { teamId: state.currentTeamId }
        },
        {
          query: WEEKDAY_INTERACTIONS_QUERY,
          variables: {
            teamId: state.currentTeamId,
            sprintId: state.currentSprintId,
            weekNumber: state.currentWeekNumber
          }
        },
        {
          query: GET_VELOCITY_TREND,
          variables: { teamId: state.currentTeamId }
        },
        {
          query: GET_BURNDOWN_QUERY,
          variables: {
            teamId: state.currentTeamId,
            sprintId: state.currentSprintId
          }
        },
        {
          query: MEMBER_LOAD_QUERY,
          variables: {
            teamId: state.currentTeamId,
            sprintId: state.currentSprintId
          }
        }
      ],
      onCompleted: async data => {
        const payload = {
          currentTeamStatus: data.runWeek.team.currentStatus,
          currentSprintId: data.runWeek.team.currentSprintId,
          currentSprintNumber: data.runWeek.team.currentSprintNumber,
          currentWeekNumber: data.runWeek.team.currentWeekNumber,
          currentWeekDay: data.runWeek.team.currentWeekDay,
          currentRunDay: data.runWeek.team.currentRunDay
        };

        await dispatch({
          type: "RUN_WEEK_UPDATE",
          payload
        });
      }
    }
  );

  const [
    processConditionResponse,
    { loading: cloading, error: cError }
  ] = useMutation(PROCESS_CONDITION_RESPONSE);
  const {
    loading,
    team,
    runWeekStatusObj,
    updateRunWeekStatus,
    conditionResponse,
    updateResponse,
    postRunWeekUpdates
  } = props;

  const renderMe = () => {
    if (loading) {
      return (
        <Alert key={1} color="success">
          The team is working the backlog.{" "}
        </Alert>
      );
    }
    if (props.classroomStatus === "REGISTRATION") {
      return (
        <Alert key={2} color="warning">
          Please contact your instructor to run the Sprint.
        </Alert>
      );
    }
    if (
      !runWeekStatusObj ||
      runWeekStatusObj === null ||
      !runWeekStatusObj.returnStatus ||
      runWeekStatusObj.returnStatus === null ||
      runWeekStatusObj.returnStatus === "OFF"
    ) {
      return (
        <Alert key={2} color="info">
          Press the run sprint button to kick off the process
        </Alert>
      );
    }
    if (runWeekStatusObj.returnStatus) {
      if (runWeekStatusObj.returnStatus === "ERROR") {
        return (
          <div>
            <Alert key={3} color="danger">
              Error in the server side: {runWeekStatusObj.returnMessage}
            </Alert>
          </div>
        );
      }
      // && condition.silent === false
      if (runWeekStatusObj.returnStatus === "COND") {
        return (
          <div>
            <ConditionPanel
              runWeek={runWeekStatusObj}
              conditionResponse={conditionResponse}
              updateResponse={updateResponse}
            />
          </div>
        );
      }
      if (runWeekStatusObj.returnStatus === "PS") {
        return (
          <div>
            <AssistantPanel playScriptList={runWeekStatusObj.playScriptList} />
          </div>
        );
      }
      if (runWeekStatusObj.returnStatus === "EOD") {
        return (
          <div>
            <Alert key={5} color="success">
              End of the work day. Starting a new day...
            </Alert>
          </div>
        );
      }
      if (runWeekStatusObj.returnStatus === "EOW") {
        return (
          <Alert key={6} color="success">
            Please wait, forwarding to end of week reports...
          </Alert>
        );
      }
      if (runWeekStatusObj.returnStatus === "ON") {
        return (
          <Alert key={7} color="success">
            Working....
          </Alert>
        );
      }
      if (
        runWeekStatusObj.returnStatus === "OFF" ||
        runWeekStatusObj.returnStatus === ""
      ) {
        return (
          <Alert key={8} color="info">
            Press the Run Sprint button to kick off the process
          </Alert>
        );
      }
      if (!runWeekStatusObj.returnStatus) {
        return (
          <Alert key={9} color="warning">
            Sprint run interrupted? Press the Continue button below to continue
          </Alert>
        );
      }
    }
    return (
      <Alert key={10} color="info">
        Press the run sprint button to kick off the process
      </Alert>
    );
  };

  // two functions called - either process the condition response
  // or runweek

  const processResponse = async () => {
    const conditionId = Number(runWeekStatusObj.condition.id);
    if (
      !runWeekStatusObj ||
      !runWeekStatusObj.condition ||
      runWeekStatusObj.condition.id === 0
    ) {
      await updateResponse(0);
    }
    if (conditionResponse > 0) {
      const result = await processConditionResponse({
        variables: {
          teamId: team.id,
          conditionId,
          conditionResponse
        }
      });
      await updateResponse(0); // reset this
      if (result) {
        await updateRunWeekStatus(result.data.processConditionResponse);

        return handleRunWeekResponse(result.data.processConditionResponse);
      }
    }
    // eslint-disable-next-line no-use-before-define
    // performRunWeek();
    return null;
  };

  const handleRunWeekResponse = async result => {
    let keepRunning = false;
    // silent conditions are now handled on the server

    if (
      result.returnStatus === "COND" &&
      result.condition &&
      result.condition.silent === true
    ) {
      keepRunning = true;
      await updateResponse(1);
      processConditionResponse();
    } else if (result.returnStatus === "EOD") {
      keepRunning = true;
    } else if (
      // autorun
      result.condition &&
      result.returnStatus === "COND" &&
      state.autoRun === true
    ) {
      keepRunning = true;
      let r = 1;
      let responseCount = 1;
      if (state.autoRun === true && state.autoRunRandom === true) {
        // need to randomize the response r
        responseCount = result.conditionResponseList.length;
        r = Math.floor(Math.random() * responseCount + 1); // 0 start
        if (r > result.conditionResponseList.length + 1 || r < 1) {
          r = result.conditionResponseList.length;
        }
      }
      await updateResponse(r);
    } else if (result.returnStatus === "PS" && state.autoRun === true) {
      keepRunning = true;
      await updateResponse(1);
    } else if (result.returnStatus === "ON") {
      keepRunning = true;
    } else {
      // need to stop on client for response
      await updateResponse(0);
    }

    if (keepRunning === true) {
      // eslint-disable-next-line no-use-before-define
      performRunWeek();
    }
  };

  const performRunWeek = async () => {
    const teamId = state.currentTeamId;
    let conditionId = 0;
    if (
      !runWeekStatusObj ||
      !runWeekStatusObj.condition ||
      runWeekStatusObj.condition.id === 0
    ) {
      await updateResponse(0);
    }

    if (conditionId === undefined || conditionId == null) {
      conditionId = 0;
    }

    const result = await runWeek({
      variables: {
        teamId,
        conditionId: conditionId || 0
      }
    });

    if (result) {
      await updateRunWeekStatus(result.data.runWeek);

      if (result.data.runWeek) {
        if (
          result.data.runWeek.returnStatus === "EOW" ||
          result.data.runWeek.returnStatus === "EOP"
        ) {
          await postRunWeekUpdates();
          await updateRunWeekStatus({});
        } else {
          await handleRunWeekResponse(result.data.runWeek);
        }
      }
    }
  };
  
  const handleClick = async () => {
    // eslint-disable-next-line no-use-before-define
    if (runWeekStatusObj.returnStatus === "COND") {
      return processResponse();
    }
    if (runWeekStatusObj.returnStatus === "PS") {
      return performRunWeek();
    }
    return performRunWeek();
  };
  return (
    <>
      {renderMe()}
      <RunButton
        loading={
          cloading || rwLoading || runWeekStatusObj.returnStatus === "EOW"
        }
        disabled={
          (runWeekStatusObj.returnStatus === "COND" &&
            conditionResponse === 0) ||
          props.classroomStatus === "REGISTRATION"
        }
        currentDay={state.currentWeekDay}
        currentWeek={state.currentWeekNumber}
        runWeekStatusObj={runWeekStatusObj}
        handleClick={event => handleClick(event)}
      />
    </>
  );
};

export default CommunicationsCard;
