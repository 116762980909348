/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
import React, { Fragment, useContext } from "react";
import { Container, Button } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import Planning from "routes/Planning/Planning";
import AppHeader from "./AppHeader";
import Sidebar from "./Sidebar/Sidebar";
import DisplayHeader from "./Header";
import Footer from "./Footer";
import ScrumBoard from "../routes/ScrumBoard/ScrumBoard";
import BacklogContainer from "../routes/Planning/BacklogContainer";
import Calendar from "../routes/Calendar/Calendar";
import RunSprintContainer from "../routes/RunWeek/RunSprintContainer";
import Teams from "../routes/Teams/Teams";
import Reports from "../routes/Reports/Reports";
import Welcome from "../routes/Teams/components/Welcome";
import TeamReview from "../routes/Teams/components/TeamReview";
import Students from "../routes/Students/Students";
import StudentOverview from "../routes/StudentOverview/StudentOverview";
import Classrooms from "../routes/Classrooms/Classrooms";
import Settings from "../routes/Settings/Settings";
import Loading, { Error } from "./Loading";
import CenteredLayout from "./CenteredLayout";
import SentryErrorBoundary from "./SentryErrorBoundary";
import { ME_QUERY } from "../graphql/queries";
import { checkHasActiveTeam, checkHasCompletedTeam } from "../utilities/utils";
import MemberLoad from "../routes/Reports/MemberLoad";
import HistoryReport from "../routes/Reports/HistoryReport";
import SprintBurndown from "../routes/Reports/SprintBurndown";
import VelocityTrend from "../routes/Reports/VelocityTrend";
import CumulativeFlow from "../routes/Reports/CumulativeFlow";
import EndOfRun from "../routes/Reports/EndOfRun";
import { ProjectContext } from "../GlobalState";

export default function AppRouter(props) {
  const [state] = useContext(ProjectContext);
  const { auth } = props;
  const { data, loading, error } = useQuery(ME_QUERY);

  if (loading)
    return (
      <CenteredLayout>
        <Loading text="" />
      </CenteredLayout>
    );
  if (error)
    return (
      <CenteredLayout>
        <Error error={error} />
        <Button onClick={() => auth.logout()}>Log out</Button>
      </CenteredLayout>
    );

  if (!data.me) auth.logout();

  if (data.me) {
    Sentry.configureScope(scope => {
      const token = localStorage.getItem("access_token");
      // eslint-disable-next-line prefer-const
      let response = data.me;
      response.access_token = token;
      scope.setUser(response);
    });

    const getRunNumber = () => {
      const teamId = data.me.currentTeam.id;
      const run = data.me.classStudentRuns.find(r => r.team.id === teamId);
      return run.runSequenceNumber;
    };

    return (
      <div className="app">
        <Fragment>
          <AppHeader currentUser={data.me} />
          <div className="app-body">
            <Sidebar
              startedProject={data.me.startedFirstProject === true}
              currentUser={data.me}
              hasActiveTeam={checkHasActiveTeam(data.me)}
              hasCompletedTeam={checkHasCompletedTeam(data.me)}
            />
            <main className="main">
              <DisplayHeader
                currentUser={data.me}
                userId={data.me.id}
                name={`${data.me.firstName} ${data.me.lastName}`}
              />
              <Container fluid>
                <SentryErrorBoundary>
                  <Switch>
                    <Route
                      exact
                      path="/welcome"
                      name="Welcome"
                      render={routeProps => (
                        <Welcome
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/teams"
                      name="Runs"
                      render={routeProps => (
                        <Teams
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      path="/teams/review/:id"
                      name="Run Review"
                      render={routeProps => (
                        <TeamReview
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/management"
                      name="Student Management"
                      render={routeProps => (
                        <Students id={data.me.id} {...routeProps} />
                      )}
                    />
                    <Route
                      exact
                      path="/management/students"
                      name="Student Management"
                      render={routeProps => (
                        <Students id={data.me.id} {...routeProps} />
                      )}
                    />
                    <Route
                      path="/management/students/:id"
                      name="Student Overview"
                      render={routeProps => (
                        <StudentOverview
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/management/classrooms"
                      name="Classroom Management"
                      render={routeProps => (
                        <Classrooms
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/settings"
                      name="Settings"
                      render={routeProps => (
                        <Settings
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/scrumboard"
                      name="Scrumboard"
                      render={routeProps => (
                        <ScrumBoard
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/planning"
                      name="Backlog"
                      render={routeProps => (
                        <Planning
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/backlog"
                      name="Backlog"
                      render={routeProps => (
                        <BacklogContainer
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/calendar"
                      name="Calendar"
                      render={routeProps => (
                        <Calendar
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/run-sprint"
                      name="RunWeek"
                      render={routeProps => (
                        <RunSprintContainer
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports"
                      name="Reports"
                      render={routeProps => (
                        <Reports
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports/history"
                      name="History"
                      render={routeProps => (
                        <HistoryReport
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports/memberLoad"
                      name="Member Load"
                      render={routeProps => (
                        <MemberLoad
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports/sprintBurndown"
                      name="Sprint Burndown"
                      render={routeProps => (
                        <SprintBurndown
                          currentUser={data.me}
                          projectContext={state}
                          runNumber={getRunNumber()}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports/velocityTrend"
                      name="Velocity Trend"
                      render={routeProps => (
                        <VelocityTrend
                          currentUser={data.me}
                          projectContext={state}
                          runNumber={getRunNumber()}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports/cumulativeFlow"
                      name="Velocity Trend"
                      render={routeProps => (
                        <CumulativeFlow
                          currentUser={data.me}
                          projectContext={state}
                          runNumber={getRunNumber()}
                          {...routeProps}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reports/endOfRun"
                      name="Velocity Trend"
                      render={routeProps => (
                        <EndOfRun
                          currentUser={data.me}
                          projectContext={state}
                          {...routeProps}
                        />
                      )}
                    />
                  </Switch>
                </SentryErrorBoundary>
              </Container>
            </main>
          </div>
          <Footer />
        </Fragment>
      </div>
    );
  }
  return <span />;
}
